<template>
  <div class="loginDiv">
    <form name="form">
      <div class="logoDiv">
        <img width="30px" height="30px" src="img/tvweb/teamviewlogo.svg" />
        <img width="170px" height="90px" src="img/tvweb/tvweblogo.svg" />
      </div>
      <div class="textDiv">
        <p>
          {{ this.tvWebTextLongPartOne }}
        </p>
      </div>
      <div class="textDiv">
        <p>
          <a :href="this.dsvgoLink" class="dsvgoLink" target="_blank">{{
            this.tvWebTextLink
          }}</a>
          <br /><br />
          {{ this.legalTextOne }}
          <a :href="this.legalLinkOne" class="dsvgoLink" target="_blank">{{
            this.userManual
          }}</a>
          {{ this.legalTextTwo }}
          <a :href="this.legalLinkTwo" class="dsvgoLink" target="_blank">{{
            this.securityManual
          }}</a>
          {{ this.legalTextThree }}
          <br />
        </p>
      </div>
      <div class="cbDiv">
        <label class="switch">
          <input type="checkbox" @change="handleChange($event)" v-model="cacheChoice" />
          <span class="slider round"></span>
        </label>
        <p>{{ this.tvWebCheckBoxText }}</p>
      </div>
      <div class="text-center btnDiv">
        <br />
      </div>
      <div v-if="loginDisplay" class="credentialsDiv credDiv">
        <label>{{ this.usernameText }}</label>
        <input
          class="loginInput"
          name="username"
          @keyup.enter="doLogin"
          ref="username"
          v-model="username"
        />
        <label>{{ this.passwordText }}</label>
        <input
          class="loginInput centered-input input-group-alternative mb-3"
          name="password"
          type="password"
          ref="password"
          @keyup.enter="doLogin"
          v-model="password"
        />
      </div>
      <span style="white-space: pre-wrap" class="serverErr" v-if="serverErr">
        <i style="margin-right: 10px" class="fas fa-exclamation-circle"></i>
        {{ serverErr }}
      </span>

      <div class="msgBoxDiv">
        <span style="white-space: pre-wrap" class="checkMsg" v-if="checkMsg">
          <i style="margin-right: 10px" class="fas fa-exclamation-circle"></i>
          {{ checkMsg }}
        </span>
      </div>
      <div class="text-center btnDiv">
        <base-button class="dialogBtn" @click="closeDialog">{{
          this.cancelText
        }}</base-button>
        <base-button class="dialogBtn" @click="doLogin">{{ this.exam }}</base-button>
      </div>
    </form>
  </div>
</template>

<script>
import VuejsDialogMixin from "vuejs-dialog/dist/vuejs-dialog-mixin.min.js";
import axios from "axios";
import Vue from "vue";
import i18n from "../i18n.js";
import TvWebDataLoader from "../helper/tvWebDataLoader.js";

var sha256 = require("js-sha256");

export default {
  mixins: [VuejsDialogMixin],

  data() {
    return {
      tvWebTextLongPartOne: "",
      tvWebTextLongPartTwo: "",
      legalTextOne: "",
      legalTextTwo: "",
      legalTextThree: "",
      legalLinkOne: "",
      legalLinkTwo: "",
      tvWebTextLink: "",
      securityManual: "",
      userManual: "",
      dsvgoLink: "",
      tvWebCheckBoxText: "",
      usernameText: "",
      passwordText: "",
      cancelText: "",
      exam: "",
      cacheChoice: localStorage.getItem("teamViewDisplay") == "true" ? true : false,
      username: "",
      password: "",
      serverErr: "",
      checkMsg: "",
      componentKey: 0,
      loginDisplay: localStorage.getItem("teamViewDisplay") == "true" ? false : true,
    };
  },

  mounted() {
    this.loader = new TvWebDataLoader();

    this.tvWebTextLongPartOne = i18n.t("tvWebTextLongPartOne");
    this.tvWebTextLongPartTwo = i18n.t("tvWebTextLongPartTwo");
    this.legalTextOne = i18n.t("legalTextOne");
    this.legalTextTwo = i18n.t("legalTextTwo");
    this.legalTextThree = i18n.t("legalTextThree");
    this.legalLinkOne = Vue.prototype.$baseUrl + i18n.t("legalLinkOne");
    this.legalLinkTwo = Vue.prototype.$baseUrl + i18n.t("legalLinkTwo");
    this.tvWebCheckBoxText = i18n.t("tvWebCheckBoxText");
    this.tvWebTextLink = i18n.t("tvWebTextLink");
    this.securityManual = i18n.t("securityManual");
    this.userManual = i18n.t("userManual");
    this.dsvgoLink = i18n.t("dsvgoLink");
    this.usernameText = i18n.t("username");
    this.passwordText = i18n.t("password");
    this.cancelText = i18n.t("cancel");
    this.exam = i18n.t("exam");
  },

  methods: {
    closeDialog() {
      this.close();
    },

    handleChange(cb) {
      if (cb.target.checked) {
        this.checkMsg = i18n.t("tvDefault");
        localStorage.setItem("teamViewDisplay", true);
      } else {
        this.checkMsg = i18n.t("tvNotDefault");
        localStorage.removeItem("teamViewDisplay");
      }

      setTimeout(() => {
        this.checkMsg = "";
      }, 3000);
    },

    async doLogin() {
      this.serverErr = "";

      const hash = sha256.create();
      hash.update(this.password);

      if (!this.loginDisplay) {
        await this.checkIfValidLicence();
      } else {
        if (this.username === "" || this.password === "") {
          this.serverErr = i18n.t("mandatoryData");
          this.loginDisplay = true;
          return;
        }

        this.serverErr = "";
        try {
          await this.loginRequest(this.username, hash.hex());
        } catch (error) {
          this.serverErr = i18n.t("loginFailed");
          console.log(error);
        }
      }
    },

    async loginRequest(username, passwordHash) {
      try {
        const response = await axios.post(`${Vue.prototype.$authUrl}/login`, {
          username: username,
          password: passwordHash,
        });

        if (response.data.roles[1] === "true") {

          this.openTwWeb();

          if (this.cacheChoice) {
            localStorage.setItem("teamViewDisplay", true);
            localStorage.setItem("hdsConnectUsername", username);
            localStorage.setItem("hdsConnectPwd", passwordHash);
          } else {
            localStorage.removeItem("teamViewDisplay");
            localStorage.removeItem("hdsConnectUsername");
            localStorage.removeItem("hdsConnectPwd");
          }
        } else {
          this.loginDisplay = true;
          this.serverErr = i18n.t("licenseInvalid");
          localStorage.removeItem("teamViewDisplay");
        }
      } catch (error) {
        this.serverErr = i18n.t("loginFailed");
        console.log(error);
        throw error;
      }
    },

    async openTwWeb() {
      this.closeDialog();      
      this.loader.prepareTvWebPayload();
    },

    async checkIfValidLicence() {
      const username = localStorage.getItem("hdsConnectUsername");
      const pwd = localStorage.getItem("hdsConnectPwd");

      if (username == null || pwd == null) {
        this.loginDisplay = true;
      }

      try {
        await this.loginRequest(username, pwd);
      } catch (error) {
        this.serverErr = i18n.t("loginFailed");
        console.log(error);
      }
    },
  },
};
</script>

<style scoped="">
.loginDiv {
  text-align: left;
}
.dsvgoLink {
  color: #56c7f5;
}

.dsvgoLink:hover {
  color: white;
}

.loginDiv p {
  font-size: 14px !important;
  color: white;
}

.cbDiv {
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 5px;
  height: 32px;
  width: 550px;
}

.cbDiv p {
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
}

.textDiv {
  margin-bottom: 20px;
}

.dialogBtn {
  width: 250px;
  height: 35px;
  padding: 5px;
  text-transform: uppercase;
  font-size: 13px !important;
}

.loginInput {
  width: 400px;
  padding: 5px;
  text-align: center;
}

.loginDiv {
  margin: 0px;
  padding: 20px;
  width: 650px;
  color: white;
}

input {
  border-radius: 8px !important;
  height: 28px !important;
  background-color: #167a81;
  color: white !important;
  border: 0px;
}

.credentialsDiv {
  width: 550px;
  margin: auto;
  font-size: 14px !important;
}

button {
  width: 100%;
  margin-bottom: 10px;
  float: none;
  font-size: 14px !important;
}

.serverErr {
  background-color: #fce4e4;
  border: 1px dotted black;
  font-size: 0.8em;
  padding: 8px 8px;
  display: block;
  text-align: center;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin: 10px;
  color: black;
}

.msgBoxDiv {
  height: 20px;
  color: black;
  margin-bottom: 10px;
  margin-left: 40px;
}

.checkMsg {
  background-color: #eafce4;
  font-size: 0.8em;
  height: 22px;
  width: 400px;
  text-align: center;
  padding: 2px;
  margin: 0 auto;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 99999;
}

div.credDiv {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
  padding-left: 20px;
}
div.credDiv label {
  text-align: left;
}
div.credDiv label:after {
  content: ":";
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  margin-top: 4px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #167a81;
}

input:focus + .slider {
  box-shadow: 0 0 1px #167a81;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
