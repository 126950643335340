import axios from 'axios';
import Vue from "vue";
import { eventBus } from "../main.js";

export const dlxAuth = {

  data: {
    listData: [],
    hac: null,
    patBirthdate: null,
    token: null,
    progressPollingInterval: null,
    progress: 0,
  },

  actions: {

    resetJWT(){
      this.token = null;
    },

    async login(hacCode, bDate) {
      var me = this;

      try {
        const tfaTokenRequest = {
          tfaAnswer: [[
            { questionId: 1, answer: bDate }
          ]]
        };
        this.token = null;
        const response = await axios.post(`${Vue.prototype.$dlxUrl}/v1/tokentfa/${hacCode}`, tfaTokenRequest);
    
        if (response.data && response.data.access_token) {
          response.data.expiresAt = new Date().getTime() + response.data.expires_in * 1000;
          const listData = await this.getList(response.data.access_token);
          me.token = response.data.access_token;

          if (listData) {
            dlxAuth.data.listData = listData;
            dlxAuth.data.hac = hacCode;
            dlxAuth.data.patBirthdate = bDate;
            dlxAuth.data.patientsName = listData[0][0].patientsName;
            dlxAuth.data.patientsSex = listData[0][0].patientsSex;
            return true; 
          }
          else {
            return "error";
          }            
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          return "403"; 
        }else if (error.response && error.response.status === 401) {
          return "401"; 
        }

        return "error"; 
      }
    },

    async getList(accessToken) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        };
        const response = await axios.get(`${Vue.prototype.$dlxUrl}/v1/list`, config);
        return response.data.dataItem;

      } catch (error) {
        return false;
      }
    },
    
    async pollDownloadProgress(startTime, progressRequestTime) {

      const maxInterval = 10000; 

      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      };

      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime; 

      if (elapsedTime > 5000) {  
        clearInterval(this.progressPollingInterval);
        progressRequestTime *= 1.10;

        if (progressRequestTime > maxInterval) {
          progressRequestTime = maxInterval;
        }

        this.progressPollingInterval = setInterval(() => {
          this.pollDownloadProgress(startTime, progressRequestTime);
        }, progressRequestTime); 

      }

      try {
        const response = await axios.get(`${Vue.prototype.$dlxUrl}/v1/download/progress`, config);
        
        if (response.data && response.data.progress !== undefined) {
          //progress changed
          if (response.data.progress !=  this.progress) {
            this.progress = response.data.progress;  
            eventBus.$emit('dlxProgress', this.progress);
          }

          if (this.progress >= 99) {
            clearInterval(this.progressPollingInterval);
            eventBus.$emit('dlxProgressDone', this.progress);
          }
        }
      } catch (error) {
        console.error("Error fetching download progress", error);
        clearInterval(this.progressPollingInterval);
      }
    },

    async downloadItemsByIds(documents) {
      
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      };

      const startTime = Date.now();  
      
      this.progressPollingInterval = setInterval(() => {
        this.pollDownloadProgress(startTime, 3000);
      }, 3000);
    
      const documentEntries = Object.entries(documents);
    
      const results = [];
      for (let i = 0; i < documentEntries.length; i++) {
        const [id, { docType, description }] = documentEntries[i];
    
        try {
          const response = await axios.get(`${Vue.prototype.$dlxUrl}/v1/download/${id}`, {
            ...config,
            responseType: 'blob',
          });
    
          const fileExtension = docType.toLowerCase().includes('pdf') ? 'pdf' : 'zip';
          const fileName = `${description}.${fileExtension}`;
          
          this.triggerFileDownload(response.data, fileName, false);
        
          results.push({ description, success: true });
        } catch (error) {
          results.push({ description, error: true });
        }
      }

      clearInterval(this.progressPollingInterval);
      eventBus.$emit('dlxProgressDone', this.progress);    

      return results;
    },

    async downloadAll(hac) {
      const startTime = Date.now();  

      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      };

      this.progressPollingInterval = setInterval(() => {
        this.pollDownloadProgress(startTime, 3000);
      }, 3000);

      const timestamp = new Date().getTime();
      const url = `${Vue.prototype.$dlxUrl}/v1/downloadall?t=${timestamp}`;

      try {
        const response = await axios.get(url, {
          ...config,
          responseType: 'blob',
        });

        const fileName = `${hac}.zip`
        this.triggerFileDownload(response.data, fileName, true);

        return true;
      } catch (error) {
        clearInterval(this.progressPollingInterval);
        eventBus.$emit('dlxProgressDone', this.progress);
        return false;
      }

    },

    triggerFileDownload(blob, fileName, isLastItem) {
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      if (isLastItem) { 
        clearInterval(this.progressPollingInterval);
        eventBus.$emit('dlxProgressDone', this.progress);
      }    
    }
  }
};
