<template>
  <div class="dlxWindowStyle">
    <div class="dialogWrapper">
    <div>
      <loading :active="this.activeLoading" :isFullPage="false"></loading>
    </div>
    <div class="headerSection">
      <div class="headerRow">
        <p class="hacCodeStyle">{{ accessCode }} {{ hac }}</p>
        <p class="birthdateStyle">{{ birthdateLabel }} {{ bDate | formatDate }}</p>
      </div>
      <div class="headerRow">
        <p class="hacCodeStyle">{{ patLabel }} {{ patientsName }}</p>
        <p class="birthdateStyle">{{ sexLabel }} {{ patientsSex }}</p>
      </div>
    </div>
    <br />
    <div>
      <p class="titleStyle">{{ downloadTitle }}</p>
      <p class="textStyle">{{ downloadDescription }}</p>
    </div>  
    <div class="documentList">
      <div class="tableWrapper">
        <table class="documentTable" :disabled="showProgressBar">
          <thead>
            <tr>
              <th>
                <input type="checkbox" v-model="selectAll" @change="toggleSelectAll" />
                {{ selectAllText }}
              </th>
              <th>{{ typeHeader }}</th>
              <th>{{ descriptionHeader }}</th>
              <th>{{ dateHeader }}</th>
              <th>{{ modalityHeader }}</th>
              <th>{{ fileCountHeader }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(document, index) in listData" :key="index">
              <td>
                <input
                  type="checkbox"
                  v-model="document.selected"
                  @change="checkSelectAll"
                />
              </td>
              <td class="capitalize-first-letter">{{ document.mimeType }}</td>
              <td :title="document.description || ''">                
                {{
                  document.description && document.description.length > 20
                    ? document.description.substring(0, 20) + "..."
                    : document.description || ""
                }}
              </td>
              <td>{{ document.date | formatDate }}</td>
              <td :title="document.modalities ? document.modalities.join(', ') : ''">
                {{
                  document.modalities && document.modalities.length > 5
                    ? document.modalities.slice(0, 5).join(", ") +
                      (document.modalities.length > 5 ? "..." : "")
                    : document.modalities
                    ? document.modalities.join(", ")
                    : ""
                }}
              </td>
              <td>{{ document.fileCount || 1 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div class="btnContainer">
        <span
          style="white-space: pre-wrap; margin-bottom: 10px"
          class="serverErr"
          v-if="errMsg"
        >
          <i style="margin-right: 5px" class="fas fa-exclamation-circle"></i>
          {{ errMsg }}
        </span>
        <span
          style="white-space: pre-wrap; margin-bottom: 10px"
          class="successMsg"
          v-if="successMsg"
        >
          <i style="margin-right: 5px" class="fas fa-exclamation-circle"></i>
          {{ successMsg }}
        </span>
        <base-button @click="handleDownload" :disabled="showProgressBar">{{
          downloadSelected
        }}</base-button>
      </div>
      <div class="progressBarContainer" v-if="showFinaltext">
        <div class="progressLabel">      
        {{ finalText }}<span class="dots"></span>
      </div>
      </div>
      <div v-if="showProgressBar" class="progressBarContainer">
        <div class="progressLabel">
          {{ progressLabel }}<span class="dots"></span>
        </div>
        <div class="progressBar">
          <div class="progress" :style="{ width: progress + '%' }">{{ progress }}%</div>
        </div>
      </div>
    </div>
    <br />
    <div>
      <div class="expandContent">
        <p class="textStyle">
          {{ downloadProblem }}
          {{ errTextLong1 }}
          <a :href="downloadHelp" class="downloadHelp" target="_blank">{{ errText }}</a>
          {{ errTextLong2 }}
        </p>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import i18n from "../i18n.js";
import { dlxAuth } from "../store/dlxAuth";
import moment from "moment";
import Vue from "vue";
import Loading from "vue-loading-overlay";
import { eventBus } from "../main.js";

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY");
  }
});

export default {
  components: { Loading },
  data() {
    return {
      i18n,
      downloadTitle: i18n.t("downloadTitle"),
      downloadDescription: i18n.t("downloadDescription"),
      downloadSelected: i18n.t("downloadSelected"),
      selectAllText: i18n.t("selectAll"),
      showProgressBar: false,
      progress: 0,
      progressLabel: i18n.t("downloadingFiles"),
      finalText: i18n.t("downloadFinal"),
      downloadHelp: "https://healthdataspace.org/faq-professionals/",
      selectAll: false,
      downloadProblem: i18n.t("downloadProblem"),
      errTextShort: i18n.t("errextShort"),
      errTextLong1: i18n.t("errTextLong1"),
      errText: i18n.t("errText"),
      errTextLong2: i18n.t("errTextLong2"),
      errDownloadManual: "#",
      typeHeader: i18n.t("typeHeader"),
      descriptionHeader: i18n.t("descriptionHeader"),
      dateHeader: i18n.t("dateHeader"),
      modalityHeader: i18n.t("modalityHeader"),
      fileCountHeader: i18n.t("fileCountHeader"),
      patLabel: i18n.t("patientLabel"),
      sexLabel: i18n.t("sexLabel"),
      hacLabel: i18n.t("hacLabel"),
      birthdateLabel: i18n.t("birthdateLabel"),
      accessCode: i18n.t("accessCode"),
      errMsg: "",
      successMsg: "",
      listData: [],
      hac: null,
      bDate: null,
      patientsName: null,
      patientsSex: null,
      activeLoading: false,
      showFinaltext: false,

    };
  },
  mounted() {
    this.listData = dlxAuth.data.listData[0];
    this.hac = dlxAuth.data.hac;
    this.bDate = dlxAuth.data.patBirthdate;
    this.patientsName = dlxAuth.data.patientsName;
    const sex = dlxAuth.data.patientsSex.toUpperCase(); 
    this.patientsSex = sex === "M" ? i18n.t("male") : sex === "F" ? i18n.t("female") : i18n.t("unknown");

    eventBus.$on('dlxProgress', (percentage) => {

      this.showProgressBar = percentage > 0 && percentage < 99;
      this.progress = percentage;

      if (percentage >= 99) {
        this.showFinaltext = true;
        this.showProgressBar = false;        
      }
  });

    eventBus.$on('dlxProgressDone', () => {      
      this.showProgressBar = false;
  });

  },
  methods: {
    showErr(msg, timeout) {
      this.errMsg = this.i18n.t(msg);

      setTimeout(() => {
        this.errMsg = "";
      }, timeout);
    },

    showSuccess(msg, timeout) {
      this.showFinaltext = false;
      this.successMsg = this.i18n.t(msg);

      setTimeout(() => {
        this.successMsg = "";
      }, timeout);
    },

    handleDownload() {
      const anySelected = this.listData.some((document) => document.selected);
      const selectedDocuments = this.listData.filter((document) => document.selected);

      if (!anySelected) {
        this.errMsg = this.i18n.t("noDocumentsSelected");

        setTimeout(() => {
          this.errMsg = "";
        }, 2000);

        return;
      }

      this.errMsg = "";
      this.successMsg = "";

      const selectedIds = selectedDocuments.map((doc) => doc.id);
      this.downloadContent(selectedIds);
    },

    async downloadContent(selectedIds) {
      const allDocumentIds = this.listData.map((doc) => doc.id);
      const allDocumentsSelected = allDocumentIds.every((id) => selectedIds.includes(id));

      this.activeLoading = true;

      if (allDocumentsSelected) {
        this.downloadAll();
      } else {
        this.downloadByIds(selectedIds);
      }
    },

    async downloadAll() {
      let resp = await dlxAuth.actions.downloadAll(this.hac);

      if (resp == false) {
        this.showErr("dlxErrDownload", 4000);
        this.showFinaltext = false;
      } else {
        this.showSuccess("dlxSuccess", 4000);
      }

      this.activeLoading = false;
    },

    async downloadByIds(selectedIds) {
      const selectedDocumentsMap = selectedIds.reduce((acc, id) => {
        const document = this.listData.find((doc) => doc.id === id);
        if (document) {
          acc[id] = {
            docType: document.mimeType,
            description: document.description,
          };
        }
        return acc;
      }, {});

      let resp = await dlxAuth.actions.downloadItemsByIds(selectedDocumentsMap);

      const failedDownloads = [];

      resp.forEach((result) => {
        if (!result.success) {
          failedDownloads.push(result.description);
        }
      });

      if (failedDownloads.length > 0) {
        let msg = this.i18n.t("dlxDownloadFailed") + `${failedDownloads.join(", ")}`;
        this.showErr(msg, 4000);
        this.showFinaltext = false;
      } else {
        this.showSuccess("dlxSuccess", 4000);
      }

      this.activeLoading = false;
    },

    toggleSelectAll() {
      this.listData.forEach((document) => {
        document.selected = this.selectAll;
      });
    },
    checkSelectAll() {
      const allSelected = this.listData.every((document) => document.selected);
      this.selectAll = allSelected;
    },
    getFileCount(document) {
      if (document.type === "Report") {
        return 1;
      }
      return document.fileCount;
    },
  },
};
</script>

<style scoped>
.successMsg {
  background-color: #00e69a;
  border: 1px solid #fcc2c3;
  padding: 8px 8px;
  display: block;
  text-align: center;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.capitalize-first-letter::first-letter {
  text-transform: uppercase;
}

td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.dialogWrapper {
  position: relative;
  overflow: hidden; 
  padding: 20px;
  width:100%;
}

.headerSection {
  display: flex;
  flex-direction: column;
  color: #292929;
  margin-bottom: 20px;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.hacCodeStyle,
.birthdateStyle {
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
  text-align: center;
}

.titleStyle {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  color: #e6f2f2;
}

.textStyle {
  font-size: 16px;
  text-align: center;
  color: #e6f2f2;
}

.btnContainer {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.base-button {
  margin-right: 10px;
}

.documentList {
  margin-top: 20px;
  border-radius: 25px;
}

.documentTable {
  font-family: "Roboto", sans-serif;
  text-align: center;
  color: black;
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
}

.tableWrapper {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #5e5e5e;
  max-height:250px;
  overflow-y: auto;

}

.documentTable th,
.documentTable td {
  text-align: center;
  padding: 8px;
}

.documentTable th {
  background-color: #3f8e8e;
  color: white;
}

.documentTable tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.documentTable tbody tr:nth-child(even) {
  background-color: #e6f7f7;
}

.documentTable tbody tr:hover {
  background-color: #d3e9e9;
}

.downloadHelp {
  color: black;
  text-decoration: underline;
}

.progressBarContainer {
  margin-top: 20px;
  text-align: center;
  z-index: 9999;
}

.progressLabel {
  font-size: 16px;
  margin-bottom: 5px;
  color: black;
  font-weight: bold; 
  z-index: 9999; 
}

.progressBar {
  width: 100%;
  background-color: #e0e0e0; 
  border-radius: 25px;
  overflow: hidden;
  margin: 10px auto;
  height: 20px;
  position: relative;
  border: 2px solid #bbb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  z-index: 9999;
}

.progress {
  height: 100%;
  background-color: #4caf50;
  text-align: center;
  color: white;
  line-height: 20px;
  white-space: nowrap;
  transition: width 0.4s ease;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.dots::after {
  content: '.';
  animation: dots 2s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    content: '';
  }
  40% {
    content: '.';
  }
  60% {
    content: '..';
  }
  80%, 100% {
    content: '...';
  }
}

</style>
