
/*
=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import { router } from './router';
import './registerServiceWorker'
import i18n from './i18n'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ArgonDashboard from './plugins/argon-dashboard';
import { store } from './store'
import axios from 'axios'

Vue.config.productionTip = false
Vue.use(ArgonDashboard);

export const eventBus = new Vue(); 

//Should help clear cache by reloading page
axios.interceptors.response.use(
  (resp) => {
    let v = resp.headers['vers'] || 'default'
    if(v !== localStorage.getItem('vers') && resp.config.method == 'get'){
      localStorage.setItem('vers', v)
      window.location.reload() // For new version, simply reload on any get
     }
  return Promise.resolve(resp)
})

axios.get(process.env.BASE_URL + "config.json")
.then((response) => { 
  Vue.prototype.$serverUrl = response.data.serverNameUrl
  Vue.prototype.$authUrl = response.data.authUrl
  Vue.prototype.$baseUrl = response.data.baseUrl
  Vue.prototype.$rsUrl = response.data.rsUrl
  Vue.prototype.$dlxUrl = response.data.dlxUrl

       new Vue({
        router,
        i18n,
        store,
        render: h => h(App)
      }).$mount('#app');
})




