<template>
  <div class="windowStyle">
    <div>
      <p class="titleStyle">
        {{ this.download }}
      </p>
      <p class="textStyle">
        {{ this.downloadType }}
      </p>
    </div>
    <div class="btnContainer">
      <base-button @click="DICOM">{{ "DICOM" }}</base-button>
      <base-button @click="JPEG">{{ "JPEG" }}</base-button>
      <base-button @click="BOTH">{{ "DICOM & JPEG" }}</base-button>
      <base-button @click="openDlxDialog">{{ "DLX" }}</base-button>
    </div>
    <div>
      <br />
      <div class="expandContent">
        <p class="downloadProblem" v-if="showDownloadText">
          {{ this.managerTextShort }}
        </p>
        <p class="textStyle">
          {{ this.managerTextLong1 }}
          <a
            :href="this.managerDownloadManual"
            class="managerDownloadLink"
            target="_blank"
            >{{ this.managerText }}</a
          >
          {{ this.managerTextLong2 }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../i18n.js";
import { eventBus } from "../main.js";
import VuejsDialogMixin from "vuejs-dialog/dist/vuejs-dialog-mixin.min.js";
import CustomDownloadView from "@/views/DlxDownload"
import Vue from 'vue';
import accountStore from '../store/accountStore';

const customDownloadView = "myView";
Vue.dialog.registerComponent(customDownloadView, CustomDownloadView);
import { dlxAuth } from '../store/dlxAuth';


export default {
    mixins: [VuejsDialogMixin],

    data() {
        return {
            // Prepare text variables
            managerTextShort: "",
            managerTextLong1: "",
            managerTextLong2: "",
            managerText: "",
            managerDownloadManual: "",
            downloadType: "",
            download: "",
            userOs: "",
            showDownloadText: false,
        };
    },

    mounted() {
        // Set text from locals de.json or en.json, dependend on selected language
        this.managerTextShort = i18n.t("managerTextShort");
        this.downloadType = i18n.t("downloadType");
        this.download = i18n.t("download");

        this.userOs = navigator.platform || "";
        if(this.userOs.toLowerCase().includes('win')){
            this.showDownloadText = true
            this.managerDownloadManual = i18n.t("managerDownloadManual");
            this.managerTextLong1 = i18n.t("managerTextLong1");
            this.managerTextLong2 = i18n.t("managerTextLong2");
            this.managerText = i18n.t("managerText");
        }
    },

    methods: {
        DICOM() {
            eventBus.$emit('startDownload', "dicom");
            this.closeDialog();
        },
        JPEG() {
            eventBus.$emit('startDownload', "jpeg");
            this.closeDialog();
        },
        BOTH() {
            eventBus.$emit('startDownload', "all");
            this.closeDialog();
        },

        closeDialog() {
            this.close();
        },

        formatDateToYYYYMMDD(dateObj) {
            const year = dateObj.getFullYear();
            const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
            const day = ('0' + dateObj.getDate()).slice(-2);

            return `${year}${month}${day}`;
        },

        async openDlxDialog() {

           var me = this;
           let hac = localStorage.getItem('plainHac');

           const account = accountStore.data.account;
           let birthDateObj = new Date(account.birthdateDecrypted);

            const birthDateStr = this.formatDateToYYYYMMDD(birthDateObj);
            const listDataRetrieved = await dlxAuth.actions.login(hac, birthDateStr);
            this.activeLoading = false;

            if (listDataRetrieved === true) {
            this.$dialog.confirm(null, {
                view: customDownloadView,
                html: true,
                backdropClose: true,

            }).catch(function() {
                me.resetDLXJwt();
            });
            } else {
            if (listDataRetrieved === "403") {
                this.validationMsg = i18n.t("emptyHacShell");
            } else if (listDataRetrieved === "401") {
                this.validationMsg = i18n.t("wrongCredentials");
            } else {
                this.validationMsg = i18n.t("dlxErr");
            }

            setTimeout(() => {
                this.validationMsg = "";
            }, 6000);

            }
        },

        resetDLXJwt() {
            dlxAuth.actions.resetJWT();
        },
    }


}
</script>

<style scoped>
.windowStyle {
  width: auto;
  max-width: 500px;
  margin: 20px auto;
}

.textStyle {
  color: white;
  text-align: center;
  font-size: 15px;
  margin: 5px 0;
}

.downloadProblem {
  color: white;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}

.managerDownloadLink {
  color: #0fe4fc;
  font-weight: bold;
  text-decoration: underline;
}

.titleStyle {
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.btnContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.expandContent {
  font-size: 15px;
  margin-top: 10px;
}
</style>
